import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    SHr,
    SLoad,
    SNavigation,
    SPage,
    SText,
    SView,
    SPopup,
    STheme,
    SImage,
    SDate,
    SIcon,
    SThread,
    SButtom,
} from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Container from '../../Components/Container';
import Model from '../../Model';
import AccentBar from '../../Components/AccentBar';
import { Linking } from 'react-native';
import { Platform } from 'react-native';


import { Parent } from '.';
import MapaRastreo from './Components/MapaRastreo';
import Popups from '../../Components/Popups';
import PedidoState from './Components/PedidoState';
import Roles from '../../Roles';

class root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios: {},
            timeImage: new Date().getTime()
        };
        this.pk = SNavigation.getParam('pk');
    }

    componentDidMount() {
        new SThread(200, 'pedido_detalle_thre', true).start(() => {
            this.setState({ ready: true });
        });

        Roles.getPermiso({
            key_rol: Model.restaurante.Action.getSelectKeyRol(),
            url: "/_partner/pedido",
            permiso: "entregar"
        }).then(e => {
            this.setState({ entregar: true })
            console.log(e);
        }).catch(e => {
        })

        // this.loadData()
        this.isRun = true
        this.hilo();
    }


    componentWillUnmount() {
        this.isRun = false;
    }

    hilo() {
        if (!this.isRun) return;
        this.loadData()
        new SThread(15 * 1000, "esperar_hilo_pedido").start(() => {
            if (!this.isRun) return;
            this.hilo();
        })
    }

    loadData() {
        SSocket.sendPromise({
            component: "pedido",
            type: "getDetalle",
            key_pedido: this.pk,
            key_usuario: Model.usuario.Action.getKey()
        }).then(res => {
            let data = res.data
            if (data?.restaurante?.key != Model.restaurante.Action.getSelect()?.key) {
                SPopup.alert('Este pedido es de otro restaurante.');
                SNavigation.goBack('/');
            }

            this.setState({ data: res.data, })

            this.getUser(res.data)
            if (res.data.key_conductor) {
                this.getUser({ key_usuario: res.data.key_conductor });
            }
        }).catch(err => {
            console.error(err.data)
        })
    }

    getUser(data) {
        let keys = [data.key_usuario];
        // console.log("asdasdas", !!this.state?.usuarios[data?.key_usuario])
        if (!!this.state?.usuarios[data?.key_usuario]) return;
        SSocket.sendPromise({
            version: "2.0",
            service: "usuario",
            component: "usuario",
            type: "getAllKeys",
            keys: keys,
        }).then(resp => {
            if (!this.state?.usuarios) this.state.usuarios = {};
            this.state.usuarios = {
                ...this.state.usuarios,
                ...resp.data
            }
            this.setState({ ...this.state })
        }).catch(e2 => {
            SPopup.alert(e2.error)
        })
    }

    componentUsuario() {
        let borderRadius = 5;

        let phoneWp = this?.state?.data?.factura?.phone.replace(/\+| /g, '');
        let redirectLink = `https://api.whatsapp.com/send?phone=${phoneWp}`

        let usuario = this.state.usuarios ? this.state.usuarios[this.state?.data?.key_usuario]?.usuario : null
        return <>
            {/* <SText font={'Montserrat-ExtraBold'} fontSize={16}>DATOS DEL CLIENTE</SText> */}
            <SText font={'Montserrat-Bold'} fontSize={16}>DATOS DEL CLIENTE</SText>
            <SText fontSize={11} color={STheme.color.gray}>(Si te falta algún item, para salvar el pedido, puedes comunicarte con el cliente presionando en el número de teléfono y ofrecer un producto equivalente al precio)</SText>
            <SHr />

            <SView row >
                <SView row flex
                    style={{
                        alignItems: "center"
                    }}
                >
                    {
                        usuario ?
                            <>
                                <SView width={40} height={40}>
                                    <SImage src={require("../../Assets/img/no_foto_user.png")} style={{ borderRadius: 10 }} />
                                    <SImage src={SSocket.api.root + "usuario/" + usuario?.key + "?date=" + this.state.timeImage} style={{ borderRadius: 10, position: 'absolute', resizeMode: "cover" }} />
                                </SView>

                                <SText flex fontSize={16} style={{ paddingLeft: 5, paddingRight: 5 }}>{`${usuario?.Nombres} ${usuario?.Apellidos}`}</SText>
                            </>
                            : <SLoad />
                    }
                </SView>

                <SView row
                    // col={"xs-6"}
                    onPress={() => {
                        Linking.openURL(redirectLink)
                    }}
                >
                    <SView
                        center
                        height={40}
                        backgroundColor={"#7dcc00"}
                        style={{
                            padding: 5,
                            borderTopLeftRadius: borderRadius,
                            borderBottomLeftRadius: borderRadius
                        }}
                    >
                        <SIcon name={'whatsapp'} fill={"#fff"} height={30} width={30} />
                    </SView>
                    <SView
                        center
                        height={40}
                        style={{
                            padding: 5,
                            backgroundColor: STheme.color.lightGray,
                            borderTopRightRadius: borderRadius,
                            borderBottomRightRadius: borderRadius
                        }}
                    >
                        <SText>{this.state.data?.factura?.phone}</SText>
                    </SView>
                </SView>
            </SView>
        </>
    }

    imprimirComanda() {
        if (Platform.OS === 'web') {
            return <>
                <SView row center
                    style={{
                        backgroundColor: STheme.color.primary,
                        padding: 8,
                        borderRadius: 5,
                        width: 200,
                        height: 30,
                        justifyContent: 'space-evenly'
                    }}
                    onPress={() => {
                        SNavigation.navigate(Parent.path + '/comanda', { pk: this.pk });
                    }}
                >
                    <SView height={18} width={18} >
                        <SImage src={require("../../Assets/img/imprimir.png")} style={{ borderRadius: 10, position: 'absolute' }} />
                    </SView>
                    <SText
                        font={'Montserrat-SemiBold'}
                        style={{
                            color: STheme.color.white,
                            fontSize: 12,
                        }}
                    >Imprimir Comanda</SText>
                </SView>
            </>
        }
    }

    componentDatosPedido() {
        let data = this.state.data;
        return <SView row>
            <SView flex>
                {/* <SText font={'Montserrat-ExtraBold'}>Datos de Facturación:</SText> */}
                <SText font={'Montserrat-Bold'}>Datos de Facturación:</SText>
                <SText color={data?.factura?.razon_social ? STheme.color.text : STheme.color.gray}>RS: {data?.factura?.razon_social || data?.factura?.razon_social != '' ? data?.factura?.razon_social : "* usuario no puso RS"}</SText>
                <SText color={data?.factura?.nit ? STheme.color.text : STheme.color.gray}>NIT: {data?.factura?.nit || data?.factura?.nit != '' ? data?.factura?.nit : "* usuario no puso nit"}</SText>
                <SHr />
                {/* <SText font={'Montserrat-ExtraBold'}>Nota del cliente:</SText> */}
                <SText font={'Montserrat-Bold'}>Nota del cliente:</SText>
                <SText color={data?.nota_cliente ? STheme.color.text : STheme.color.gray}>{data?.nota_cliente ? data?.nota_cliente : "* El cliente no puso nota"}</SText>
            </SView>

            <SView>
                <SText font={'Montserrat-Bold'}>Hora del Pedido</SText>
                <SText font={'Montserrat-Bold'} fontSize={20}
                    style={{
                        alignItems: "center"
                    }}
                >{new SDate(data.fecha_on, "yyyy-MM-ddThh:mm").toString("hh:mm")}</SText>
            </SView>
        </SView>
    }

    componentDetallePedido() {
        let data = this.state.data;
        let detalleTapeke = {
            1: {
                sub_producto_detalle: [{
                    key: 'd119f-ed3450',
                    nombre: 'Recuerda que debes entregar:'
                }]
            },
            2: {
                sub_producto_detalle: [{
                    key: 'd119f-ed3451',
                    nombre: '• Cantidad generosa'
                }]
            },
            3: {
                sub_producto_detalle: [{
                    key: 'd119f-ed3452',
                    nombre: '• Excelente calidad'
                }]
            },
        };

        return <SView col={'xs-12'}>
            <SView row
                style={{
                    justifyContent: "space-between"
                }}
            >
                {/* <SText font={'Montserrat-ExtraBold'}>DETALLE DEL PEDIDO</SText> */}
                <SText font={'Montserrat-Bold'}>DETALLE DEL PEDIDO</SText>
                <SView center>
                    <SText font={'Montserrat-Bold'}>#{data.key.slice(0, 6)}</SText>
                    <SText fontSize={10}>Código del pedido</SText>
                </SView>
            </SView>

            <SHr />
            {
                data.cantidad > 0 ?
                    this.cardProducto({
                        image: require("../../Assets/img/BOLSA-TAPEKE-MENU-APP.png"),
                        title: "Tapeke",
                        cantidad: data.cantidad,
                        precio: (data.cantidad * data.precio),
                        detalle: detalleTapeke
                    })
                    : null
            }

            {
                this.listCardProducts(data?.pedido_producto)
            }
        </SView >
    }

    listCardProducts(pedido_producto) {
        return pedido_producto?.map(prod => {
            return this.cardProducto({
                key: prod.key,
                image: SSocket.api.root + "producto/.128_" + prod.key_producto + "?date=" + this.state.timeImage,
                title: prod.descripcion,
                cantidad: prod.cantidad,
                precio: prod?.precio_sin_descuento ? (prod?.precio_sin_descuento * prod.cantidad) : (prod.precio * prod.cantidad),
                detalle: prod.sub_productos,
                descuento: prod?.precio_sin_descuento ? (prod.cantidad * prod.precio_sin_descuento) - (prod.cantidad * prod.precio) : 0
            })
        })
    }

    cardProducto({ key, image, title, cantidad, precio, detalle, descuento }) {
        const cardDetalle = () => {
            let det = Object.values(detalle);
            if (det.length == 0) {
                return <SText color={STheme.color.gray} fontSize={10}>No hay subproducto</SText>
            }
            return det.map(det => {
                return Object.values(det.sub_producto_detalle).map(subdet => {
                    return <SText color={STheme.color.gray} fontSize={10} key={det.key}>{`${subdet.cantidad ? subdet.cantidad + "x" : ""} ${subdet.nombre} ${subdet.precio > 0 ? "- " + (subdet.precio * subdet.cantidad) + " Bs." : ""}`}</SText>
                })
            })
        };

        return <>
            <SView key={key} card row
                style={{
                    padding: 10,
                    borderRadius: 10
                }}
            >
                <SView height={60} width={60}>
                    <SImage src={require("../../Assets/img/no_image.jpeg")} style={{ borderRadius: 10 }} />
                    <SImage src={image} style={{ borderRadius: 10, position: 'absolute', resizeMode: "cover" }} />
                </SView>

                <SView
                    flex
                    style={{
                        marginLeft: 10
                    }}
                >
                    <SText font={"Montserrat-Bold"} col={"xs-12"}>{`${title}`}</SText>
                    {cardDetalle()}
                </SView>

                <SView center>
                    <SText font={"Montserrat-Bold"} fontSize={13} color={STheme.color.primary}>Cantidad</SText>
                    <SText font={"Montserrat-SemiBold"}>{cantidad}</SText>
                    <SText font={"Montserrat-Bold"} fontSize={13} color={STheme.color.primary}>Precio</SText>
                    <SText font={"Montserrat-SemiBold"}> Bs. {parseFloat(precio).toFixed(2)}</SText>
                    {
                        descuento > 0 ?
                            <>
                                <SText font={"Montserrat-Bold"} fontSize={13} color={STheme.color.danger}>Descuento</SText>
                                <SText> Bs. {parseFloat(descuento).toFixed(2)}</SText>
                            </>
                            : null
                    }
                </SView>
            </SView>
            <SHr />
        </>
    }

    cardTypePedido() {
        return <SView center col={'xs-12'} card padding={10} borderRadius={5}>
            <SText font={"Montserrat-SemiBold"} color={STheme.color.primary}>{this.state.data.delivery > 0 ? "Entrega a domicilio" : "Recoger del lugar"}</SText>
            {
                this.state.data.delivery == 0 && this.state.data?.data_extra?.horario_recogida ?
                    <SView row center>
                        <SText fontSize={12} style={{ justifyContent: "center" }}>Horario estimado de recogida:</SText>
                        <SText font={"Montserrat-SemiBold"} fontSize={16} style={{ paddingLeft: 5 }}>{this.state.data?.data_extra?.horario_recogida}</SText>
                        <SText color={STheme.color.gray} fontSize={9} >* Recuerda que debes reservar este pedido y tenerlo listo a la hora estimada</SText>
                    </SView>

                    : null
            }
        </SView>
    }

    labelDetallePedido({ label, labelColor = STheme.color.text, value, color = STheme.color.text, font, simbol = "" }) {
        return <SView row
            style={{
                justifyContent: "space-between"
            }}
        >
            <SText color={labelColor}>{label}</SText>
            <SText font={font ?? 'Montserrat-Medium'} color={color}>{typeof (value) === 'number' ? `${simbol} Bs. ${value}` : value}</SText>
        </SView>
    }

    totalProducto() {
        let total = 0

        if (this.state?.data?.pedido_producto) {
            Object.values(this.state?.data?.pedido_producto).map(prod => {
                if (prod.precio_sin_descuento) {
                    total += (prod.cantidad * prod.precio_sin_descuento)
                } else {
                    total += (prod.cantidad * prod.precio)
                }
            })
        }


        return total;
    }

    totalSubProductoDetalle() {
        let total = 0

        if (this.state.data.pedido_producto) {
            Object.values(this.state.data.pedido_producto).map(pp => {
                total += pp.monto_total_subproducto_detalle
            })
        }

        return total;
    }

    totalDescuentoIteamCubrePartner() {
        let total = 0

        if (this.state.data.pedido_producto) {
            Object.values(this.state.data.pedido_producto).map(prod => {
                if (prod.precio_sin_descuento) {
                    total += (prod.cantidad * prod.precio_sin_descuento) - (prod.cantidad * prod.precio)
                }
            })
        }

        return total;
    }

    tipoDePago(tipo_pago) {
        if (tipo_pago && tipo_pago?.length > 0) {
            return !!tipo_pago.find(o => o.type == "efectivo") ? "Efectivo" : `Online - ${tipo_pago[0].type}`;
        } else {
            return "El pago con QR nunca se pago";
        }
    }


    detallePedido() {
        let data = this.state.data
        let totalTapeke = (data.cantidad * data.precio)
        let totalProducto = this.totalProducto()
        let totalSubProd = this.totalSubProductoDetalle();

        let descuento = this.totalDescuentoIteamCubrePartner();

        let total = totalTapeke
            + totalProducto + totalSubProd - (descuento)

        return <>
            <SText font={"Montserrat-Bold"}>DETALLE DE COMPRA</SText>
            {this.labelDetallePedido({ label: "Método de pago", value: this.tipoDePago(data?.tipo_pago), color: STheme.color.text, font: 'Montserrat-Bold' })}
            {totalTapeke > 0 ? this.labelDetallePedido({ label: "Total Tapekes", value: totalTapeke ?? 0 }) : null}
            {totalProducto > 0 ? this.labelDetallePedido({ label: "Total Producto", value: totalProducto }) : null}
            {totalSubProd > 0 ? this.labelDetallePedido({ label: "Total SubProducto", value: totalSubProd ?? 0 }) : null}
            {descuento > 0 ? this.labelDetallePedido({ label: "Descuento cubre Partner", value: descuento, color: STheme.color.danger, simbol: "-" }) : null}
            <SHr color={STheme.color.gray} h={1} />
            {this.labelDetallePedido({ label: "Total:", labelColor: STheme.color.text, value: total, color: STheme.color.text })}
        </>
    }

    componentSoporte() {
        return <SView>
            <SText center fontSize={9} color={STheme.color.gray}>*El cliente puede pasar a recoger su pedido durante tu horario de atención, por lo tanto deberás reservarlo. En caso de que no pase, contactate con soporte.</SText>
            <SHr h={20} />
            <SView center row
                onPress={() => {
                    SNavigation.navigate("/soporte");
                }}
            >
                <SView height={30} width={30}>
                    <SImage src={require('../../Assets/img/contacto.png')} />
                </SView>
                <SText font={'Montserrat-SemiBold'} fontSize={15} style={{ paddingLeft: 10 }}>Contactar a Soporte</SText>
            </SView>
        </SView>
    }

    renderButtomAcceptar() {
        const data = this.state.data;
        if (!this.state.entregar) return null;
        if (data.delivery > 0 && data?.state != "esperando_conductor") return null;
        if (data.delivery <= 0 && data?.state != "listo") return null;

        return <SView
            col={'xs-12'}
            center
        >
            <SHr height={40} />
            {data.state == 'en_camino' ||
                data.state == 'entregado' ||
                data.state == 'no_recogido' ? (
                <SView
                    col={'xs-11'}
                    center
                    backgroundColor={'#96BE00'}
                    style={{ borderRadius: 4, overflow: 'hidden' }}
                >
                    <SHr height={20} />
                    <SView col={'xs-11'}>
                        <PedidoState data={data} />
                    </SView>
                    <SHr height={20} />
                </SView>
            ) : (
                <SButtom
                    style={{
                        backgroundColor: STheme.color.primary,
                        width: 300,
                        fontSize: 40,
                        borderRadius: 8,
                    }}
                    onPress={() => {
                        if (
                            data?.restaurante?.key !=
                            Model.restaurante.Action.getSelect()?.key
                        ) {
                            SPopup.alert(
                                'Este pedido es de otro restaurante.'
                            );
                            SNavigation.reset('/');
                            return;
                        }
                        var mensaje = '';
                        if (
                            data.state != 'listo' &&
                            data.state != 'esperando_conductor'
                        ) {
                            switch (data.state) {
                                case 'buscando_conductor':
                                    Popups.Alert.open({
                                        title: 'No se puede entregar el pedido.',
                                        label: 'No puede entregar porque seguimos buscando Driver para este pedido',
                                    });
                                    break;
                                case 'pagado':
                                    Popups.Alert.open({
                                        title: 'No se puede entregar el pedido.',
                                        label: 'No puede entregar porque aun no se encuentra listo o es para otro horario.',
                                    });
                                    break;
                                case 'entregado_conductor':
                                    Popups.Alert.open({
                                        title: 'No se puede entregar el pedido.',
                                        label: 'No puede entregar porque este pedido ya fue entregado a un driver.',
                                    });
                                    break;
                                default:
                                    Popups.Alert.open({
                                        title: 'No se puede entregar el pedido.',
                                        label:
                                            'No puedes entregar el pedido cuando se encuentra en estado ' +
                                            data.state,
                                    });
                                    break;
                            }
                        } else {
                            Model.pedido.Action.entregar(
                                this.pk,
                                this.props
                            )
                                .then(e => {
                                    Model.pedido.Action.CLEAR();
                                    SNavigation.goBack();
                                })
                                .catch(e => {
                                    Popups.Alert.open({
                                        title: 'No se puede entregar el pedido.',
                                        label: e.error,
                                    });
                                });
                        }
                    }}
                >
                    <SText color={'#fff'}>ENTREGAR</SText>
                </SButtom>
            )}
            <SHr height={40} />
        </SView>
    }

    renderConductor() {
        if (!this.state.data) return null;
        if (!this.state.data.key_conductor) return null;
        if (!this.state.usuarios) return null;
        const usuario = this.state.usuarios[this.state.data.key_conductor];
        if (!usuario) return null;
        const { Nombres, Apellidos, Telefono } = usuario.usuario;

        let phoneWp = Telefono.replace(/\+| /g, '');
        let redirectLink = `https://api.whatsapp.com/send?phone=${phoneWp}`

        return <SView col={"xs-12"} height={40} row center>
            <SView flex height style={{
                alignItems: "center"
            }} row>
                <SIcon name={"iconoDelivery"} fill={STheme.color.primary} width={50} height={50} />
                <SView width={8} />
                <SText font='Montserrat-Medium'>{Nombres} {Apellidos}</SText>
            </SView>
            <SView width={150} center row
                onPress={() => {
                    Linking.openURL(redirectLink)
                }}
            >
                <SView width={20} height={20}>
                    <SIcon name={"whatsapp"} fill={"#7dcc00"} />
                </SView>
                <SView width={8} />
                <SText font='Montserrat-Medium'>{Telefono}</SText>
            </SView>
        </SView>
    }

    cardState() {
        let state = this.state.data.state;

        let includeState = ["entregado_conductor", "conductor_llego", "entregado"];
        let excludeState = ["cancelado", "no_recogido", "anulado"];

        let textoState = ""

        if (includeState.includes(state) || excludeState.includes(state)) {
            textoState = `${state.replace("_", " ")}` 
        }

        return <SView center card padding={15}>
            <SText font={"Montserrat-SemiBold"} color={STheme.color.primary}>{`${textoState}`}</SText>
        </SView>
    }

    renderContent() {
        if (!this.state.data) return <SLoad />;

        const validateState = () => {
            let state = this.state.data.state;
            if (state == "buscando_conductor" || state == "confirmando_conductor" || state == "esperando_conductor") return true;
        }

        return <Container center={false}>
            <SHr h={20} />
            {this.componentUsuario()}
            <SHr h={20} />
            {this.componentDatosPedido()}
            <SHr h={20} />
            {this.componentDetallePedido()}
            <SHr h={20} />
            {this.cardTypePedido()}
            <SHr h={20} />
            <SView center>
                {this.imprimirComanda()}
            </SView>
            <SHr h={20} />
            {this.detallePedido()}
            <SHr h={20} />

            {
                this.state.data.delivery > 0 && validateState() ?
                    <>
                        <MapaRastreo key_pedido={this.pk} data={this.state.data} />
                        {this.renderConductor()}
                    </>
                    : null
            }

            {
                !validateState() ?
                    this.cardState()
                    : null
            }

            {/* <SView center border={"#FF00FF"}>
                <SText font={'Montserrat-Bold'}>Aca va el componente mapa para el rastreo y botónes de cambio de estados</SText>
            </SView> */}
            <SHr h={20} />
            {this.renderButtomAcceptar()}
            <SHr h={20} />
            {this.componentSoporte()}
            <SHr h={20} />
        </Container>;
    }

    render() {
        if (!this.state.ready) return <SLoad />;

        return (
            <SPage onRefresh={() => {
                this.loadData()
            }}
                header={<AccentBar />}
            >
                {this.renderContent()}
            </SPage>
        );
    }
}
const initStates = state => {
    return { state };
};
export default connect(initStates)(root);
