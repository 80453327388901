import React, { Component } from 'react'
import { SMapView, SPage, SText, SView, } from 'servisofts-component'
class test extends Component {
    render() {
        return (
            <SPage disableScroll>
         
            </SPage>
        )
    }
}

export default test