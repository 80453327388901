import BilleteraSinFondos from "./types/BilleteraSinFondos"
import TapekesAgotados from "./types/TapekesAgotados"
import InicieSession from "./types/InicieSession"
import Alert from "./types/Alert"
import AceptarCondiciones from "./types/AceptarCondiciones"
export default {
    BilleteraSinFondos,
    TapekesAgotados,
    InicieSession,
    Alert,
    AceptarCondiciones
}